.App {
    overflow-x: hidden;
}

.home-wrapper {
    width: 100vw;
    height: 100vh;
    background: var(--color-bg);
}

.app-content {
    overflow-x: hidden;
}

.hide-app {
    display: none;
}

.home {
    background: url("./images/home_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}

.avatar {
    width: 500px;
}

.hello-im {
    font-size: 24pt;
}

.name {
    color: var(--color-primary);
    font-size: 54pt;
    font-weight: 600;
}

@media screen and (max-width: 900px) {
    .home {
        flex-direction: column;
        gap: 0;
        flex-flow: column-reverse;
        text-align: center;
    }

    .name {
        font-size: 36pt;
    }

    .hello-im {
        font-size: 16pt;
    }

    .avatar {
        width: 70vw;
    }

    .social {
        justify-content: center;
        align-items: center;
    }
}

.under-dev {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: #ffffffd0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999;
}

.hidden-content {
    display: none;
}

.social {
    margin-top: 4rem;
    display: flex;
    gap: 1rem;
}

.social a svg {
    width: 30px;
    height: 30px;
    transition: opacity 300ms ease;
}

.social a svg:hover {
    opacity: 0.5;
}

/* .linkedin svg {
    fill: #0a66c2;
}

.github svg {
    fill: #161414;
}

.whatsapp svg{
    fill: #0dc043;
}

.youtube svg {
    fill: #ff0000;
}

.facebook svg {
    fill: #1488ed;
} */

.space {
    width: 100vw;
    height: 100vh;
    background: var(--color-threejs-night);
}