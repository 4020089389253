.cursor-dot,
.cursor-dot-outline {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 1;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    z-index: 101;
}

.cursor-dot {
    width: 8px;
    height: 8px;
    background-color: #00d9ff;
}

.cursor-dot-outline {
    width: 30px;
    height: 30px;
    background-color: rgba(128, 221, 229, 0.5);
}