.about-wrapper {
    padding: 1rem;
    width: 100vw;
    min-height: 100vh;
    backdrop-filter: blur(20px);
    background: var(--color-tint);
}

.about {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
}

.avatar2 {
    width: 400px;
}

.glass {
    background: var(--color-light-alpha);
    backdrop-filter: blur(50px);
    border-radius: 2rem;
    border: 1px var(--color-light) solid;
    padding: 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.about-me {
    width: 40%;
}

.about-me h2 {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    border-bottom: 2px var(--color-dark-variant) solid;
    padding: 0 0 0.5rem;
    margin: 0 0 1rem;
    color: var(--color-dark);
}

.about-me p {
    color: var(--color-dark);
    line-height: 1.5rem;
}

.about-me-fp {
    margin-bottom: 1rem;
}

@media screen and (max-width: 900px) {

    .about-wrapper {
        min-height: 100vh;
    }

    .about {
        flex-direction: column;
        gap: 1rem;
    }

    .about-me {
        width: 95%;
    }

    .avatar2 {
        width: 70vw;
    }
}