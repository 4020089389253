.navbar {
    z-index: 10;
    padding: 0.5rem 1rem 0.3rem 1rem;
    background: var(--color-navbg);
    position: fixed;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50px;
    display: flex;
    gap: 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    backdrop-filter: blur(50px);
}

.navbar-icon > svg {
    width: 2rem;
    height: 2rem;
    fill: var(--color-dark);
    transition: all 300ms ease;
}

.navbar-icon > svg:hover {
    fill: var(--color-dark-variant);
}

@media screen and (max-width: 900px) { 

    .navbar {
        gap: 1rem;
        width: 80vw;
        justify-content: center;
    }

}

.theme svg {
    background: var(--color-dark);
    fill: var(--color-light);
    border-radius: 50%;
    padding: 0.35rem;
}

@media screen and (max-width: 600px) {
    .navbar {
        gap: 1rem;
    }

    .navbar-icon > svg {
        width: 1.5rem;
        height: 1.5rem;
    }

    .theme svg {
        padding: 0.2rem;
     }
   
}