.contact-wrapper {
    padding: 0.5rem;
    width: 100vw;
    min-height: 100vh;
    background: var(--color-bg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact {
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
}

.basic-contact {
    width: 100%;
    display: flex;
    gap: 2rem;
    justify-content: space-evenly;
}

.basic-contact > a > div {
    width: 20vw;
    height: 180px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    transition: box-shadow 300ms ease;
}

.basic-contact > a > div > h2 {
    font-size: 16pt;
}

.basic-contact > a > div:hover {
    box-shadow: none;
}

.basic-contact > a > div > svg {
    width: 40px;
    height: 40px;
    margin-bottom: 0.5rem;
}

.contact-form {
    margin: 2rem 20% 0;
    padding: 2rem;
    border-radius: 30px;
    background: var(--color-light);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contact-form > form > table {
    width: 100%;
}

.contact-form > form > table input, .contact-form > form > table textarea {
    width: 100%;
}

.contact-form > form > table td input, .contact-form > form > table td textarea {
    background: var(--color-bg);
    padding: 0.5rem;
    margin-top: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
    border: none;
    resize: none;
}

.gap {
    width: 50px;
}

.vgap {
    height: 30px;
}

.btn-submit {
    display: flex;
    align-items: center;
    padding: 0.5rem 3rem 0.5rem;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    background: var(--color-primary);
    align-items: center;
    border: none;
    border-radius: 8px;
    color: var(--color-light);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    transition: all 300ms ease;
}

.btn-submit:hover {
    box-shadow: none;
    opacity: 0.7;
}

.btn-submit > svg {
    width: 25px;
    height: 25px;
    fill: var(--color-light);
}

@media screen and (max-width: 1100px) {

    .basic-contact {
        gap: 1rem;
    }

    .basic-contact > a > div {
        width: 25vw;
    }

}

@media screen and (max-width: 600px) {

    .contact {
        width: 100%;
    }

    .basic-contact {
        flex-direction: column;
    }

     .basic-contact > a > div {
        width: 100%;
    }

    .contact-form {
        margin: 2rem 0 2rem;
    }
}

.error-border {
    border: 2px solid red !important;
}

.content-middle {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
}