.projects-wrapper {
    padding: 1rem;
    width: 100vw;
    min-height: 100vh;
    background: var(--color-bg);
    overflow-y: hidden;
}

.projects-wrapper>h2 {
    margin: 6rem 0 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    padding: 2rem;
}

.projects {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.projects>div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 28vw;
    min-width: 28vw;
}

.projects>div .div,
.projects>div .div * {
    max-width: 100%;
    min-width: 28vw;
}

.projects>div .div {
    position: relative;
    --desc-animation: hide 300ms ease;
}

.projects>div .div:hover {
    --desc-animation: reveal 300ms ease;
}

.projects>div .div > .image-desc {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-tint);
    backdrop-filter: blur(15px);
    font-size: 16pt;
    flex-direction: column;
    gap: 1rem;
    animation: var(--desc-animation);
    animation-fill-mode: forwards;

    /* the border color */
    --b: 8px;
    /* the border thickness*/
    --g: 2px;
    /* the gap on hover */

    padding: calc(var(--g) + var(--b));
    --_g: #0000 25%, var(--color-primary) 0;
    background:
        conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) var(--_i, 200%) 0 /200% var(--_i, var(--b)) no-repeat,
        conic-gradient(at bottom var(--b) left var(--b), var(--_g)) 0 var(--_i, 200%)/var(--_i, var(--b)) 200% no-repeat;
    transition: .3s, background-position .3s .3s;
    cursor: pointer;
}

.projects>div .div > .image-desc:hover {
    --_i: 100%;
    transition: .3s, background-size .3s .3s;
}

.white {
    color: #fff;
}

.white > svg {
    fill: #fff;
}

.black {
    color: #000;
}

.black > svg {
    fill: #000;
}

@keyframes reveal {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@media screen and (max-width: 900px) {
    .projects {
        gap: 1rem;
    }
    .projects>div {
        gap: 1rem;
    }
}


@media screen and (max-width: 600px) {
    .projects {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    .projects > div {
        gap: 1rem;
        max-width: 100%;
    }

}