@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap');

:root {
  --color-primary: #02bf94;
  --color-dark: #363949;
  --color-dark-variant: #7d8da1;
  --color-light: #fff;
  --color-bg: #f6f6f9;
  --color-tint: #ffffff6a;
  --color-tint-white: #ffffffb6;
  --color-tint-dark: #363949b4;
  --color-light-alpha: #ffffff7d;
  --color-navbg: #ffffff5e;
  --color-threejs-night: #0000;
}

.dark-theme {
  --color-dark: #fff;
  --color-dark-variant: #7d8da1;
  --color-light: #21272f;
  --color-bg: #171c23;
  --color-tint: #171c23b9;
  --color-tint-white: #000000b6;
  --color-tint-dark: #232532c0;
  --color-light-alpha: #0000007d;
  --color-navbg: #0000004b;
  --color-threejs-night: #171c23b9;
}

.text-muted {
  color: var(--color-dark-variant);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: none;
  color: var(--color-dark);
}

a {
  text-decoration: none;
}

body {
  /*background: var(--color-bg);*/
  font-family: 'Ubuntu';
}

html {
  scroll-behavior: smooth;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-dark);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary);
}