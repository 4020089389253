.skills-wrapper {
    padding: 1rem;
    width: 100vw;
    min-height: 100vh;
    background: var(--color-bg);
    overflow-y: hidden;
}

.skills-wrapper > h2 {
    margin: 6rem 0 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
}

.skills {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    padding: 2rem;
    place-content: center;
}

.skill-set ul {
    list-style: none;
    text-align: left;
}

.skill-set {
    height: 490px;
    text-align: center;
    
}

.skill-set > svg {
    width: 80px;
    height: 100px;
    fill: var(--color-dark);
    margin-bottom: 0.5rem;
}

.skill-set > h2 {
    font-size: 16pt;
    margin-bottom: 2rem;
}


.skill-set ul li {
    display: flex;
    align-items: center;
    margin: 0.5rem 0 0.5rem;
    gap: 0.5rem;
}

.skill-set ul li .icon-wrapper svg {
    width: 25px;
    height: 25px;
    fill: var(--color-light);
    fill: var(--color-light);
    padding: 0.2rem;
}

.skill-set ul li > .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--color-dark);
}

.skill-set:hover {
    --border-size: 3px;
    --border-angle: 0turn;
    border-radius: 0;
    border: none;
    transition: all 300ms ease;
    background-image: conic-gradient(from var(--border-angle), var(--color-light), var(--color-light) 50%, var(--color-light)), conic-gradient(from var(--border-angle), transparent 20%, #08f, #f03);
    background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover;
    background-position: center center;
    background-repeat: no-repeat;
    animation: bg-spin 3s linear infinite;
}

@keyframes bg-spin {
    to {
        --border-angle: 1turn;
   }
}

@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}


@media screen and (max-width: 1200px) {
    .skills {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        padding: 2rem;
    }

    .skills-wrapper {
        min-height: unset;
    }
}

@media screen and (max-width: 600px) {
    .skills {
        grid-template-columns: 1fr;
        gap: 1rem;
        padding: 1rem;
    }

    .skills-wrapper {
        min-height: unset;
    }
}