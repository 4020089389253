.education-wrapper {
    width: 100vw;
    height: auto;
    background: var(--color-tint);
}

.education {
    display: flex;
    height: 100%;
}

.education div {
    width: 50%;
    position: relative;
}

.education .right {
    backdrop-filter: blur(60px);
    background: var(--color-tint-white);
    height: 100%;
    padding: 6rem 2rem 6rem;
}

.education .right .outer {
    width: 100%;
}

.education .right h2 {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px var(--color-dark-variant) solid;
    align-items: center;
}

/* Card container */
.education .card {
    position: relative;
    width: 100%;
}

/* setting padding based on even or odd */
.education .card:nth-child(odd) {
    padding: 30px 0 30px 30px;
}

.education .card:nth-child(even) {
    padding: 30px 30px 30px 0;
}

/* Global ::before */
.education .card::before {
    content: "";
    position: absolute;
    width: 50%;
    border: solid var(--color-primary);
}

/* Setting the border of top, bottom, left */
.education .card:nth-child(odd)::before {
    left: 0px;
    top: -4.5px;
    bottom: -4.5px;
    border-width: 5px 0 5px 5px;
    border-radius: 50px 0 0 50px;
}

/* Setting the border of top, bottom, right */
.education .card:nth-child(even)::before {
    right: 0;
    top: 0;
    bottom: 0;
    border-width: 5px 5px 5px 0;
    border-radius: 0 50px 50px 0;
}

/* Removing the border if it is the first card */
.education .card:first-child::before {
    border-top: 0;
    border-top-left-radius: 0;
}

/* Removing the border if it is the last card  and it's odd */
.education .card:last-child:nth-child(odd)::before {
    border-bottom: 0;
    border-bottom-left-radius: 0;
}

/* Removing the border if it is the last card  and it's even */
.education .card:last-child:nth-child(even)::before {
    border-bottom: 0;
    border-bottom-right-radius: 0;
}

/* Information about the timeline */
.education .info {
    display: flex;
    flex-direction: column;
    color: var(--color-dark);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
}

/* Title of the card */
.education .title {
    color: var(--color-primary);
    position: relative;
}

/* Timeline dot  */
.education .title::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background: var(--color-bg);
    border-radius: 50%;
    border: 4px solid var(--color-primary);
}

/* text right if the card is even  */
.education .card:nth-child(even)>.info>* {
    text-align: right;
}

/* setting dot to the left if the card is odd */
.education .card:nth-child(odd)>.info>.title::before {
    left: -50px;
}

/* setting dot to the right if the card is odd */
.education .card:nth-child(even)>.info>.title::before {
    right: -50px;
}

@media screen and (max-width: 900px) {
    .education div {
        width: auto;
    }

    .education .right {
        width: 70%;
    }

    .education .left {
        width: 30%;
    }
}