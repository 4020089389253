.model {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--color-tint-dark);
    z-index: 100;
}

.show {
    display: flex;
    align-items: center;
    justify-content: center;
}

.hide {
    display: none;
}

.model-window {
    position: relative;
    width: 80%;
    padding: 2rem;
    background: var(--color-bg);
    border-radius: 20px;
    animation: model_reveal 300ms ease;
}

.model-close {
    opacity: 0.5;
    animation: model_close 200ms ease;
}

@keyframes model_reveal {
    0% {
        scale: 0.5;
        opacity: 0;
    }
    100% {
        scale: 1;
        opacity: 1;
    }
}

@keyframes model_close {
    0% {
        scale: 1;
        opacity: 1;
    }
    100% {
        scale: 0.5;
        opacity: 0;
    }
}

.model-window h2 {
    margin-bottom: 1rem;
}

.model-content {
    display: flex;
    gap: 1rem;
    height: 100%;
    max-height: 100%;
}

.carousel img {
    max-height: 350px;
    max-width: 100%;
}

.phone {
    max-width: 180px !important;
}

.thumb img {
    max-height: 50px;
}

.carousel {
    width: 30%;
}

.details {
    width: 70%;
}

.model-window > svg {
    fill: rgb(163, 0, 0);
    width: 30px;
    height: 30px;
    position: absolute;
    top: 2rem;
    right: 2rem;
}


@media screen and (max-width: 960px) {
    .model-content {
        flex-direction: column;
        max-width: 100% !important;
    }

    .model-window {
        padding: 1rem;
    }

    .model-window > svg  {
        right: 1rem !important;
        top: 1rem !important;
    }

    .details {
        max-width: 100%;
        min-width: 100%;
    }

    .phone {
        max-width: 100px !important;
    }
}

@media screen and (max-width: 600px) {
    .model-content > div {
        max-width: 100% !important;
    }

    .phone {
        max-width: 60px !important;
    }
}

.links {
    margin: 1rem 0 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.links > a {
    padding: 10px 20px 10px;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    height: 46px;
    transition: all 300ms ease;
    background: var(--color-primary);
    color: var(--color-light);
}

.links > a:hover, .btn-ps:hover {
    opacity: 0.6;
}

.links a > svg {
    fill: var(--color-light);
    width: 20px;
    height: 20px;
    position: relative !important;
}

.tech {
    margin: 0.5rem 0 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.tech > div {
    padding: 2px 12px 2px;
    border-radius: 10px;
}

.android {
    background: #00c30d;
    color: #fff;
}

.java {
    background: #b07219;
    color: #fff;
}

.php {
    background: #4F5D95;
    color: #fff;
}

.js {
    background: #f1e05a;
    color: #000;
}

.mysql {
    background: #e38c00;
    color: #000;
}

.html {
    background: #e34c26;
    color: #fff;
}

.css {
    background: #563d7c;
    color: #fff;
}

.firebase {
    background: #ffbb00;
    color: #000;
}

.csharp {
    background: #178600;
    color: #fff;
}

.cpp {
    background: #f34b7d;
    color: #000;
}

.dart {
    background: #00B4AB;
    color: #fff;
}

.flutter {
    background: #4bf3df;
    color: #000;
}

.python {
    background: #3572A5;
    color: #fff;
}